
import { defineComponent } from 'vue';
import { mapState, mapWritableState } from 'pinia';
import { IActionForm } from '../../../components/dashboard/actionForm';
import { IAction } from '../../../entities/action';
import { IActionDefinition } from '../../../entities/actionDefinition';
import { createAction, getIntegration } from '@/services/steamcord/actions';
import { useActionStore } from '@/store/actionStore';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import { formatDate } from '@/util/formatDate';
import { getRandomInt } from '@/util/getRandomInt';
import ActionForm from '@/components/dashboard/ActionForm.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const actionStore = useActionStore();
    if (actionStore.integration
        && actionStore.integration.id.toString() === to.params.id.toString()) {
      next();
      return;
    }

    const integration = await getIntegration(currentOrg.id, to.params.id.toString());
    if (!integration) {
      actionStore.integration = undefined;
      next({ name: 'Not Found' });
      return;
    }

    actionStore.integration = integration;
    await actionStore.getActionDefinitions();

    next();
  },
  components: {
    ActionForm,
  },
  computed: {
    definition(): IActionDefinition | undefined {
      return this.actionDefinitions?.find((a) => a.name === this.action.definitionName);
    },
    definitions(): IActionDefinition[] {
      return this.actionDefinitions as IActionDefinition[];
    },
    formattedDate(): string | undefined {
      if (!this.integration) {
        return undefined;
      }

      return formatDate(this.integration.createdDate as Date);
    },
    ...mapState(useActionStore, ['actionDefinitions', 'integration']),
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, { appLoading: 'loading' }),
  },
  data() {
    return {
      action: {
        name: '',
        requirements: {
          discord: false,
          discordGuildMember: false,
          discordGuildBooster: false,
          steam: false,
          steamGroupMember: false,
        },
        negateRequirements: false,
        provisionInstantly: false,
        provisionOnce: false,
        definitionName: '',
        arguments: {},
      } as IAction,
      loading: false,
    };
  },
  methods: {
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    async onSubmit() {
      if (this.loading || !this.currentOrg || !this.integration) {
        return;
      }

      if ((this.$refs.actionForm as IActionForm).validate().hasErrors) {
        return;
      }

      this.loading = true;

      await createAction(this.currentOrg?.id, this.integration.id, this.action);

      await this.$router.push({
        name: 'Integration Details',
        params: { id: this.integration.id, subdomain: this.$route.params.subdomain },
      });
    },
  },
  mounted() {
    this.appLoading = false;
  },
});
