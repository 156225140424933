
import { defineComponent, PropType } from 'vue';
import { mapState } from 'pinia';
import { IAction } from '../../entities/action';
import { IActionDefinition } from '../../entities/actionDefinition';
import { ICreateActionErrors, validateCreateActionData } from '@/services/validation/actions';
import { useActionStore } from '@/store/actionStore';
import { getRandomInt } from '@/util/getRandomInt';

// eslint-disable-next-line no-shadow
enum WhenToRedeem {
  Once = 0,
  EveryTimeRequirementsAreMet = 1,
  EveryTimeRequirementsAreNotMet = 2,
}

function whenToRun(action: IAction): number {
  if (!action) {
    return -1;
  }

  if (action.negateRequirements) {
    return WhenToRedeem.EveryTimeRequirementsAreNotMet;
  }

  if (action.provisionOnce) {
    return WhenToRedeem.Once;
  }

  return WhenToRedeem.EveryTimeRequirementsAreMet;
}

export default defineComponent({
  computed: {
    definition(): IActionDefinition | undefined {
      return this.definitions?.find((a) => a.name === this.action.definitionName);
    },
    ...mapState(useActionStore, ['actionDefinitions', 'integration']),
  },
  data() {
    return {
      action: {
        id: -1,
        integrationId: -1,
        name: '',
        requirements: {
          discord: false,
          discordGuildMember: false,
          discordGuildBooster: false,
          steam: false,
          steamGroupMember: false,
        },
        whenToRun: -1,
        definitionName: '',
        arguments: {} as { [key: string]: string },
      },
      errors: {
        nameError: '',
        whenToRunError: '',
        definitionNameError: '',
        argumentErrors: {} as { [key: string]: string },
        hasErrors: false,
      },
    };
  },
  emits: ['update:modelValue'],
  methods: {
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    validate(): ICreateActionErrors {
      if (this.definition) {
        this.definition.parameters.forEach((parameter) => {
          if (this.action.arguments[parameter.key] === undefined) {
            this.action.arguments[parameter.key] = '';
          }
        });
      }

      this.errors = validateCreateActionData(
        this.action.name, this.action.whenToRun, this.action.definitionName, this.action.arguments,
      );

      return this.errors;
    },
  },
  mounted() {
    this.action = {
      id: this.modelValue.id,
      integrationId: this.modelValue.integrationId,
      name: this.modelValue.name,
      requirements: this.modelValue.requirements,
      whenToRun: whenToRun(this.modelValue),
      definitionName: this.modelValue?.definitionName ?? '',
      arguments: this.modelValue.arguments,
    };

    this.$watch('action', (newValue: any) => {
      this.$emit('update:modelValue', {
        id: this.modelValue.id,
        integrationId: this.modelValue.integrationId,
        name: newValue.name,
        requirements: newValue.requirements,
        negateRequirements: newValue.whenToRun === WhenToRedeem.EveryTimeRequirementsAreNotMet,
        provisionOnce: newValue.whenToRun === WhenToRedeem.Once,
        definitionName: newValue.definitionName,
        arguments: newValue.arguments,
      });
    }, { deep: true });
  },
  props: {
    definitions: {
      type: Object as PropType<IActionDefinition[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<IAction>,
      required: true,
    },
  },
});
